import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container, Row, Col } from "react-grid-system"

const IndexPage = () => (
  <Layout>
    <Seo
      title="Radborne"
      description="Dive into Radborne Clothing - a fusion of 80's nostalgia, iconic video game designs, and elements of Japanese culture. Quality t-shirts that spark conversations."
    />
    <h1>Radborne Clothing</h1>
    <p>
      Dive into the world of Radborne, where nostalgia meets contemporary
      design. Our t-shirts are a fusion of the vibrant 80's spirit, iconic video
      game memories, and elements of Japanese culture. Who said style can't be
      fun? With Radborne, you get the best of both worlds: quality t-shirts that
      stand out and designs that spark conversations. Whether it's
      kanji-scripted phrases, agile ninjas, or rhythmic DJ headphones, there's a
      little something for every fun-loving soul. So, why blend in when you can
      stand out?
    </p>
    <b>
      Click a Tshirt below to view more about it on our Etsy Store,{" "}
      <Link to="https://radborne.etsy.com">click here</Link> to see the full
      range:
    </b>
    <br />
    <br />
    <Container>
      <Row>
        <Col xs={6} md={3}>
          <Link to="https://radborne.etsy.com/uk/listing/1578910054/radborne-big-red-letter-r-t-shirt">
            <StaticImage
              src="../images/tshirts-new/big-r-t-shirt.jpg"
              width={200}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Radborne Big Red R T-shirt"
              style={{ marginBottom: `1.45rem` }}
            />
          </Link>
        </Col>
        <Col xs={6} md={3}>
          <Link to="https://radborne.etsy.com/uk/listing/1593310679/radborne-dragon-kanji-circle-t-shirt">
            <StaticImage
              src="../images/tshirts-new/dragon-circle-kanji-t-shirt.jpg"
              width={200}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Dragon Circle Kanji T-shirt"
              style={{ marginBottom: `1.45rem` }}
            />
          </Link>
        </Col>
        <Col xs={6} md={3}>
          <Link to="https://radborne.etsy.com/uk/listing/1593601299/radborne-rectangle-t-shirt">
            <StaticImage
              src="../images/tshirts-new/radborne-rectangle-t-shirt.jpg"
              width={200}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Radborne Rectangle T-shirt"
              style={{ marginBottom: `1.45rem` }}
            />
          </Link>
        </Col>
        <Col xs={6} md={3}>
          <Link to="https://radborne.etsy.com/uk/listing/1592966579/radborne-dj-headphone-t-shirt">
            <StaticImage
              src="../images/tshirts-new/dj-headphones-t-shirt.jpg"
              width={200}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="I Am The DJ Kanji T-shirt"
              style={{ marginBottom: `1.45rem` }}
            />
          </Link>
        </Col>
      </Row>
      <Row>
        <Col xs={6} md={3}>
          <Link to="https://radborne.etsy.com/uk/listing/1578912150/radborne-logo-outline-t-shirt">
            <StaticImage
              src="../images/tshirts-new/radborne-outline-t-shirt.jpg"
              width={200}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Radborne Outline T-shirt"
              style={{ marginBottom: `1.45rem` }}
            />
          </Link>
        </Col>
        <Col xs={6} md={3}>
          <Link to="https://radborne.etsy.com/uk/listing/1593412977/radborne-sunset-cloud-binary-storm-t">
            <StaticImage
              src="../images/tshirts-new/binary-storm-t-shirt.jpg"
              width={200}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="A Gatsby astronaut"
              style={{ marginBottom: `1.45rem` }}
            />
          </Link>
        </Col>
        <Col xs={6} md={3}>
          <Link to="https://radborne.etsy.com/uk/listing/1592969117/radborne-ninja-kanji-t-shirt">
            <StaticImage
              src="../images/tshirts-new/ninja-kanji-t-shirt.jpg"
              width={200}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Ninja Kanji T-shirt"
              style={{ marginBottom: `1.45rem` }}
            />
          </Link>
        </Col>
        <Col xs={6} md={3}>
          <Link to="https://radborne.etsy.com/uk/listing/1579137186/radborne-kanji-t-shirt-earth-wind-fire">
            <StaticImage
              src="../images/tshirts-new/kanji-t-shirt.jpg"
              width={200}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Kanji T-shirt"
              style={{ marginBottom: `1.45rem` }}
            />
          </Link>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default IndexPage
